import MobileDetect from "mobile-detect";
import React, { useEffect, useState } from "react";
import { Root, Routes } from "react-static";
//
import "./app.css";
//
const isBrowser = typeof document !== "undefined";

const excludedPaths = [
	"/directors/the-sacred-egg/six-of-the-best-simple-ideas-beautifully-made/",
	"/directors/the-sacred-egg/six-of-the-best-us-commercials-tse/",
	"/directors/the-sacred-egg/six-of-the-best-uk-commercials/",
	"/directors/the-sacred-egg/six-of-the-best-music-videos/",
];

//
export default () => {
	const [isDetecting, setIsDetecting] = useState(isBrowser);

	useEffect(() => {
		if (isBrowser) {
			console.log(window.location.pathname);
			if (excludedPaths.includes(window.location.pathname)) {
				setIsDetecting(false);
				return;
			}

			const md = new MobileDetect(window.navigator.userAgent);

			if (md.mobile()) {
				document.location = "https://mobile.riffrafffilms.tv";
			} else {
				setIsDetecting(false);
			}
		}
	}, []);

	if (isDetecting) {
		return (
			<Root>
				<p style={{ padding: "1rem" }}>Loading...</p>
			</Root>
		);
	}

	return (
		<Root>
			<Routes />
		</Root>
	);
};
